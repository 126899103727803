@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/core/src/common/variables';

@import 'utils';
@import 'grid';

body {
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app-container {
  min-height: 100vh;

  &.bp3-dark {
    background-color: $main-background;
  }
}

// Override dialog with unlimited width
.fluid-dialog {
  padding: 0;
  width: auto;
  min-width: 500px;
  max-width: 100%;
}

.card-watched {
  padding: 0;
  position: relative;
  overflow: hidden;

  &.fill-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &.selected {
    box-shadow: 0px 0px 0px 3px rgba($pt-intent-primary, 0.75);
  }

  .item-text-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    text-shadow: 1px 1px black;

    &-top {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.5) 40%,
        rgba(0, 0, 0, 0.6) 100%
      );
    }

    &-bottom {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.5) 60%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  .title {
    position: absolute;
    top: 0;
    width: 100%;
    color: white;
    text-shadow: 1px 1px black;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 40%,
      rgba(0, 0, 0, 0.6) 100%
    );
  }

  &-footer {
    // background: rgba(0, 0, 0, 0.4);
    // box-shadow: 0 -3px 12px 4px rgba(0, 0, 0, 0.4);
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0.6) 40%,
      rgba(0, 0, 0, 0) 100%
    );
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 6px 12px;
  }

  &-rating {
    display: block !important; // overriding element level inline-block set by the lib
    position: relative;
  }

  &-muted {
    color: #cccccc;
  }
}

.img-responsive {
  display: flex;
  width: 100%;
  height: auto;
}

.flex {
  display: flex;

  &-content-between {
    justify-content: space-between;
  }

  &-items-center {
    align-items: center;
  }
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card-positioned {
  position: fixed;
  animation: grow-card 0.4s ease-out;
  animation-fill-mode: both;

  img {
    max-height: 300px;
    object-fit: cover;
  }

  .title {
    visibility: hidden;
  }
}

.seenit-logo {
  display: flex;
  align-items: center;
  font-weight: 700;
  color: black;

  .logo-eyes {
    display: inline-flex;
  }
}

.hero-box {
  text-align: center;
  padding: 40px 20px;
}

.navigation-container {
  padding-top: $pt-navbar-height;

  a {
    color: inherit;
  }

  a:active,
  a:focus,
  a:hover {
    text-decoration: none;
    outline: 0;
  }
}

.seen-rating {
  min-height: $pt-button-height-small;
  display: inline-flex !important;
  align-items: center;
}

.bp3-panel-stack-view {
  position: relative;
}

.sticky-below-header {
  position: sticky;
  background-color: $main-background;
  top: $pt-navbar-height;
  z-index: $pt-z-index-content;
}

.rating-small {
  font-size: 12px;
  line-height: 12px;
}

@keyframes grow-card {
  // 40% {
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  // box-shadow: 1px 1px 3px 2px rgba(0,0,0,0.2);
  // }
  100% {
    left: 0%;
    top: $pt-navbar-height;
    top: 0;
    // transform: translate(-50%, -50%);
    // box-shadow: 1px 1px 3px 2px rgba(0,0,0,0.2);
    width: 100%;
    height: 100%;
  }
}
