.grid {
  display: grid;

  &-card {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 8px;
    padding: 8px;
  }
}
