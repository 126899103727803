$baseSize: 4px;
$sizes: (
  '0': 0,
  '1': $baseSize * 1,
  '2': $baseSize * 2,
  '3': $baseSize * 4,
  '4': $baseSize * 8,
) !default;

$spacings: (
  'm': 'margin',
  'p': 'padding',
);

$directions: (
  'y': (
    'top',
    'bottom',
  ),
  'x': (
    'left',
    'right',
  ),
  't': (
    'top',
  ),
  'r': (
    'right',
  ),
  'b': (
    'bottom',
  ),
  'l': (
    'left',
  ),
);

$main-background: #212423;

@each $shorthand, $property in $spacings {
  .#{$shorthand} {
    @each $size, $value in $sizes {
      &-#{$size} {
        #{$property}: $value;
      }
    }

    @each $direction, $modifiers in $directions {
      &#{$direction} {
        @each $size, $value in $sizes {
          &-#{$size} {
            @each $modifier in $modifiers {
              #{$property}-#{$modifier}: $value;
            }
          }
        }
      }
    }
  }
}

.text {
  &-center {
    text-align: center;
  }

  &-left {
    text-align: left;
  }
}

.w {
  &-100 {
    width: 100%;
  }
}

.flex {
  display: flex;

  &-grow {
    flex-grow: 1;
  }

  &-items-center {
    align-items: center;
  }

  &-content-between {
    justify-content: space-between;
  }

  &-content-end {
    justify-content: end;
  }
}
